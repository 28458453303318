import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { DateTime } from 'luxon'
import { parseJson } from 'src/libs/utils'
import { useHttpClient } from 'src/hooks/http'
import { useCookies } from 'src/hooks/cookies'
import providers from 'src/providers'
import { ActiveQuestion } from 'src/providers/types'
import { emitVote } from 'src/providers/votes'
import { getActiveQuestion } from 'src/providers/polls'
import {
  SetBearerTokenAction,
  SetPollStatusAction,
  SetUserAction,
  State,
  SetActiveQuestionAction,
  SetPollAction,
  SetAgentAction,
  SetVotingType,
  SetCensusAction,
  SetPollFilesAction,
  SetDomainThemeAction,
  SetZoomCredentialsAction
} from './types'

export const useVars = () => {
  return useSelector((state: State) => state)
}

export const useVotingType = () => {
  const dispatch = useDispatch()
  const votingType = useSelector((state: State) => state.votingType)
  const setVotingType = React.useCallback(
    (votingType: State['votingType']) => {
      sessionStorage.setItem('votingType', votingType)
      dispatch<SetVotingType>({ type: 'SET-VOTING-TYPE', payload: votingType })
    },
    [dispatch]
  )

  return React.useMemo(() => ({ votingType, setVotingType }), [votingType, setVotingType])
}

export const useBearerToken = () => {
  const dispatch = useDispatch()
  const { setCookie } = useCookies()
  const bearerToken = useSelector((state: State) => state.bearerToken)

  const setBearerToken = React.useCallback(
    (bearerToken: string) => {
      setCookie('token', bearerToken)
      return dispatch<SetBearerTokenAction>({ type: 'SET-BEARER-TOKEN', payload: { bearerToken } })
    },
    [dispatch, setCookie]
  )

  return { bearerToken, setBearerToken }
}

export const useUser = () => {
  const { pollCode } = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state: State) => state.user)
  const setUser = React.useCallback(
    (payload: Partial<State['user']>) => {
      const user = parseJson(Cookies.get('user') || '')
      Cookies.set('user', JSON.stringify({ ...user, ...payload }), { expires: 1, path: `/${pollCode}` })
      return dispatch<SetUserAction>({ type: 'SET-USER', payload })
    },
    [dispatch, pollCode]
  )

  return { user, setUser }
}

export const usePollStatus = () => {
  const dispatch = useDispatch()
  const pollStatus = useSelector((state: State) => state.pollStatus)
  const setPollStatus = React.useCallback(
    (value: State['pollStatus']) => {
      return dispatch<SetPollStatusAction>({ type: 'SET-POLL-STATUS', payload: value })
    },
    [dispatch]
  )

  return { pollStatus, setPollStatus }
}

export const useActiveQuestion = () => {
  const dispatch = useDispatch()
  const activeQuestion = useSelector((state: State) => state.activeQuestion)
  const setActiveQuestion = React.useCallback(
    (value: ActiveQuestion) => {
      return dispatch<SetActiveQuestionAction>({ type: 'SET-ACTIVE-QUESTION', payload: value })
    },
    [dispatch]
  )

  return { activeQuestion, setActiveQuestion }
}

export const useLoadPoll = () => {
  const dispatch = useDispatch()
  const http = useHttpClient()
  const { poll } = useVars()

  const loadPoll = React.useCallback(
    async (params: { pollCode: string }) => {
      const { pollCode } = params
      const data = await providers.getPoll(http, { pollCode })
      dispatch<SetPollAction>({ type: 'SET-POLL', payload: data })
      return data
    },
    [dispatch, http]
  )

  return React.useMemo(
    () => ({
      poll,
      loadPoll
    }),
    [poll, loadPoll]
  )
}

export const useLoadCensus = () => {
  const dispatch = useDispatch()
  const http = useHttpClient()
  const { census } = useVars()

  const loadCensus = React.useCallback(
    async (params: { censusId: string }) => {
      const { censusId } = params
      const data = await providers.getCensus(http, { censusId })
      dispatch<SetCensusAction>({ type: 'SET-CENSUS', payload: data })
      return data
    },
    [dispatch, http]
  )

  return React.useMemo(
    () => ({
      census,
      loadCensus
    }),
    [census, loadCensus]
  )
}

export const useLoadAgent = () => {
  const http = useHttpClient()
  const dispatch = useDispatch()
  const { agent } = useVars()
  const [agentLoading, setAgentLoading] = React.useState(false)

  const loadAgent = React.useCallback(
    async (params: { pollCode: string }) => {
      setAgentLoading(true)
      const { pollCode } = params
      const data = await providers.getAgent(http, { pollCode })
      dispatch<SetAgentAction>({ type: 'SET-AGENT', payload: data })
      setAgentLoading(false)
      return data
    },
    [dispatch, http]
  )

  return React.useMemo(
    () => ({
      agent,
      loadAgent,
      agentLoading
    }),
    [agent, loadAgent, agentLoading]
  )
}

/**
 * Hook for loading Zoom credentials.
 * @returns An object containing the Zoom credentials and a function to load the credentials.
 * @example const { zoomCredentials, loadZoomCredentials } = useLoadZoomCredentials()
 */
export const useLoadZoomCredentials = () => {
  const http = useHttpClient()
  const dispatch = useDispatch()
  const { zoomCredentials } = useVars()

  const loadZoomCredentials = React.useCallback(
    async (params: { pollCode: string }) => {
      const { pollCode } = params
      const data = await providers.getPollZoomCredentials(http, { pollCode })
      dispatch<SetZoomCredentialsAction>({ type: 'SET-ZOOM-CREDENTIALS', payload: data })
      return data
    },
    [dispatch, http]
  )

  return React.useMemo(() => ({ zoomCredentials, loadZoomCredentials }), [zoomCredentials, loadZoomCredentials])
}

export const useEmitVote = () => {
  const dispatch = useDispatch()

  return React.useCallback(
    async (...params: Parameters<typeof emitVote>) => {
      await emitVote(...params)
      dispatch<SetPollStatusAction>({ type: 'SET-POLL-STATUS', payload: 'waiting' })
    },
    [dispatch]
  )
}

export const useFetchActiveQuestion = () => {
  const dispatch = useDispatch()
  const { pollStatus, activeQuestion } = useVars()

  return React.useCallback(
    async (...params: Parameters<typeof getActiveQuestion>) => {
      const endDate = activeQuestion?.endDate
      const activeQuestionHasExpired = endDate ? DateTime.now() > DateTime.fromISO(endDate) : false

      if (!activeQuestionHasExpired && !['waiting', 'question-pending', 'votes-being-processed'].includes(pollStatus)) {
        return
      }

      const data = await getActiveQuestion(...params)
      dispatch<SetPollStatusAction>({ type: 'SET-POLL-STATUS', payload: data.status })
      dispatch<SetActiveQuestionAction>({ type: 'SET-ACTIVE-QUESTION', payload: data.question })
    },
    [pollStatus, activeQuestion, dispatch]
  )
}

export const useLoadPollFiles = () => {
  const http = useHttpClient()
  const { pollCode } = useParams()
  const dispatch = useDispatch()
  const { pollFiles } = useVars()

  const loadPollFiles = React.useCallback(async () => {
    const data = await providers.getPollFiles(http, { pollCode })
    dispatch<SetPollFilesAction>({ type: 'SET-POLL-FILES', payload: data })
    return data
  }, [dispatch, http, pollCode])

  return React.useMemo(
    () => ({
      pollFiles,
      loadPollFiles
    }),
    [pollFiles, loadPollFiles]
  )
}

export const useCustomColors = () => {
  const http = useHttpClient()
  const dispatch = useDispatch()
  const { domainTheme } = useVars()

  const loadDomainTheme = React.useCallback(async () => {
    const data = await providers.getDomainTheme(http)
    dispatch<SetDomainThemeAction>({ type: 'SET-DOMAIN-THEME', payload: data })
    return data
  }, [dispatch, http])

  return { loadDomainTheme, domainTheme }
}

/**
 * Custom hook for logging out the user.
 * Clears the bearer token, local storage, session storage, and removes the token cookie.
 * @returns A function to log out the user.
 * @example const logout = useLogout()
 * @example logout()
 */
export const useLogout = () => {
  const { setBearerToken } = useBearerToken()
  const { removeCookie } = useCookies()
  const dispatch = useDispatch()

  return React.useCallback(() => {
    removeCookie('token')
    removeCookie('user')
    setBearerToken('')
    localStorage.clear()
    sessionStorage.clear()
    dispatch({ type: 'CLEAR-STATE' })
  }, [dispatch, removeCookie, setBearerToken])
}

/**
 * Custom hook for handling remote link functionality.
 * Retrieves the remote meeting link configuration and provides a function to load it.
 * @returns An object containing the function to load the remote meeting link configuration and the current remote link configuration.
 * @example const { doLoadRemoteMeetingLinkConfig, remoteLinkConfig } = useRemoteLink()
 */
export const useRemoteLink = () => {
  const http = useHttpClient()
  const dispatch = useDispatch()
  const { pollCode } = useParams()
  const { remoteLinkConfig } = useVars()

  const doLoadRemoteMeetingLinkConfig = React.useCallback(async () => {
    const data = await providers.getRemoteMeetingLink(http, { pollCode })
    dispatch({ type: 'SET-REMOTE-LINK-CONFIG', payload: data })
  }, [dispatch, http, pollCode])

  return { doLoadRemoteMeetingLinkConfig, remoteLinkConfig }
}
