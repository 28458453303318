import React from 'react'
import cn from 'classnames'
import logotype from 'src/assets/logotype.svg'
import AppLoading from 'src/components/app/AppLoading'
import { context } from './context'
import store from 'src/store'

type HeaderProps = {
  className?: string
  showProgressBar?: boolean
  id?: string
}

const Header = ({ className, showProgressBar, id }: HeaderProps) => {
  const { domainTheme } = store.useVars()
  const { logoUrl } = domainTheme ?? {}
  const { leftHeaderButton, rightHeaderButton } = React.useContext(context)
  const [loadLogoError, setLoadLogoError] = React.useState<boolean>(false)
  const [loadedLogoUrl, setLoadedLogoUrl] = React.useState<string>(logotype)

  React.useEffect(() => {
    if (logoUrl) {
      setLoadedLogoUrl(logoUrl)
    }
  }, [logoUrl])

  return (
    <header className={className} id={id}>
      <div
        className={cn('flex items-center justify-between py-16 pl-16 border-b border-neutral-80 bg-white', {
          'pr-16': !rightHeaderButton
        })}
      >
        {leftHeaderButton ?? <span className={cn({ 'w-24': rightHeaderButton })}>&nbsp;</span>}

        <div className="flex items-center justify-center h-32 min-w-[133px]">
          <img
            src={loadedLogoUrl && !loadLogoError ? loadedLogoUrl : logotype}
            className={cn('object-contain', {
              'h-32 w-[133px]': loadedLogoUrl === logotype,
              'w-fit min-w-[133px] max-h-32': loadedLogoUrl !== logotype
            })}
            alt=""
            onError={() => setLoadLogoError(true)}
          />
        </div>

        {rightHeaderButton ?? <>&nbsp;</>}
      </div>
      {showProgressBar && <AppLoading />}
    </header>
  )
}

export default Header
