import React from 'react'
import axios, { AxiosError } from 'axios'
import store from 'src/store'
import { useLogout } from 'src/store/hooks'
import { useNotification } from './notifications'
import { useTranslator } from './i18n'

export const useHttpClient = () => {
  const t = useTranslator('server-errors')
  const { bearerToken } = store.useBearerToken()
  const { sendNotification } = useNotification()
  const logout = useLogout()
  const awsWafCaptcha = window?.AwsWafCaptcha

  return React.useMemo(() => {
    const captchaRequired = (error: AxiosError): boolean =>
      error.response?.status === 405 && error.response?.headers['x-amzn-waf-action'] === 'captcha'

    const showCaptchaElements = (): void => {
      document.body.style.cursor = 'wait'
      document.getElementById('captchaHeader')!.style.display = 'block'
      document.getElementById('captchaModalContainer')!.style.display = 'block'
      document.getElementById('captchaModal')!.style.display = 'block'
      document.getElementById('root')!.style.display = 'none'
    }
    const hideCaptchaElements = (): void => {
      document.getElementById('captchaHeader')!.style.display = 'none'
      document.getElementById('captchaModalContainer')!.style.display = 'none'
      document.getElementById('captchaModal')!.style.display = 'none'
      document.getElementById('root')!.style.display = 'initial'
    }
    const handleCaptchaLoad = (): void => {
      document.body.style.cursor = 'default'
      document.getElementById('captchaModalContainer')?.focus()
      document.getElementById('captchaModal')?.focus()
    }
    const handleCaptchaSuccess =
      (resolve: (value: string) => void) =>
      (wafToken: string): void => {
        hideCaptchaElements()
        resolve(wafToken)
      }
    const handleCaptchaError = (): void => {
      throw new Error('Error rendering captcha')
    }

    const renderCaptcha = (captchaForm: HTMLFormElement): Promise<string> => {
      return new Promise((resolve) => {
        awsWafCaptcha?.renderCaptcha(captchaForm, {
          apiKey: process.env.REACT_APP_CAPTCHA_API_KEY ?? '',
          onSuccess: handleCaptchaSuccess(resolve),
          onLoad: handleCaptchaLoad,
          onError: handleCaptchaError
        })
      })
    }

    const renderCaptchaModal = async (): Promise<string> => {
      const captchaForm = document.getElementById('captchaForm') as HTMLFormElement
      showCaptchaElements()

      if (!awsWafCaptcha) {
        document.getElementById('captchaModalContainer')?.focus()
        document.getElementById('captchaModal')?.focus()
        throw new Error('AWS WAF Captcha not found')
      }

      return renderCaptcha(captchaForm)
    }

    const httpClient = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        ...(bearerToken && { Authorization: `Bearer ${bearerToken}` })
      }
    })

    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (captchaRequired(error)) {
          return renderCaptchaModal().then((token) => {
            error.config.headers['x-aws-waf-token'] = token
            return httpClient.request(error.config)
          })
        } else if (error instanceof AxiosError && error.response?.status == 401) {
          logout()
          sendNotification({ severity: 'danger', message: t`expired-session` })
        } else {
          return Promise.reject(error)
        }
      }
    )

    httpClient.interceptors.request.use(
      async (config) => {
        const token = await window?.AwsWafIntegration?.getToken()
        config.headers['x-aws-waf-token'] = token
        return config
      },
      (error) => Promise.reject(error)
    )

    return httpClient
  }, [bearerToken, logout, sendNotification, awsWafCaptcha, t])
}
