import React from 'react'
import { AxiosError } from 'axios'
import { useTranslator } from './i18n'

export const useErrorParser = () => {
  const t = useTranslator('server-errors')

  const errorParser = React.useCallback(
    (error: any) => {
      if (!error) {
        return ''
      }

      if (error instanceof AxiosError && error.response?.status == 401) {
        return t`expired-session`
      } else if (typeof error == 'object') {
        const code = error.code
        const responseCode = error.response?.data.code
        if (code == 'NotAuthorizedException') {
          return t`invalid-credentials`
        } else if (responseCode == 'VOTE_ALREADY_RECORDED') {
          return t`user-already-voted`
        } else if (responseCode == 'UNEXPECTED_ERROR') {
          return t`unexpected-error`
        }
      } else if (typeof error == 'string') {
        if (error == 'No current user') {
          return t`no-current-user`
        }
      } else if (error instanceof AxiosError) {
        const data = error.response?.data
        const mainErrorCode = data?.errorCode
        const secErrorCode = Object.keys(data?.params || {})[0]

        if (mainErrorCode == 'PARAMETER_BAD_FORMAT') {
          return t`parameter-bad-format`
        } else if (mainErrorCode == 'ENTITY_NOT_FOUND') {
          return t`entity-not-found`
        } else if (mainErrorCode == 'ENTITY_ALREADY_EXIST') {
          return t`entity-already-exist`
        } else if (mainErrorCode == 'ENTITIES_IDS_DONT_MATCH') {
          return t`entities-ids-dont-match`
        } else if (mainErrorCode == 'BAD_FIELD_NAME_REFERENCE') {
          return t`bad-field-name-reference`
        } else if (mainErrorCode == 'ENTITY_NOT_VALID' && secErrorCode == 'DOCUMENT_ID_REPEATED') {
          return t`document-id-repeated`
        } else if (mainErrorCode == 'ENTITY_NOT_VALID') {
          return t`entity-not-valid`
        } else if (mainErrorCode == 'UNEXPECTED_ERROR') {
          return t`unexpected-error`
        } else if (mainErrorCode == 'FORBIDDEN_SERVICE') {
          return t`forbidden-service`
        }
      }

      return error.message ?? error
    },
    [t]
  )

  return errorParser
}
